<template>
  <div class="work-order-gather">
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="网点安装工单统计" />

    <div class="header" v-if="isHide">
      <input @keyup.enter="keydownFn" v-model="keyword" type="text" class="inputClass" /><van-icon
        class="iconClass"
        @click="searchFn"
        name="search"
      />
      <van-button @click="downLoadFn" type="info" size="small">下载报表 </van-button>
      <img @click="showFn" class="svgClass" src="@/assets/icon/hspIcon.svg" />
    </div>
    <!-- <div :class="isHide ? '' : 'widDiv'">
      <div class="table_header" :style="isHide ? 'height: calc(100vh - 46px - 53.333px);' : ' height: 100vw;'">
        <div v-for="(item, index) in dataList" :key="index">
          <div class="header wrap" style="width:200px">{{ item.company }}</div>
          <div class="header wrap" style="width:200px">{{ item.websit_name }}{{ item.websit_number }}</div>
          <div class="header wrap">{{ item.appoint_reserve_today }}</div>
          <div class="header wrap">{{ item.appoint_reserve_machine_today }}</div>
          <div class="header wrap">{{ item.completed_today }}</div>
          <div class="header wrap">{{ item.completed_machine_today }}</div>
          <div class="header wrap">{{ item.unfinished_today }}</div>
          <div class="header wrap">{{ item.unfinished_machine_today }}</div>
          <div class="header wrap">{{ item.unfinished_all }}</div>
          <div class="header wrap">{{ item.unfinished_machine_all }}</div>
          <div class="header wrap">{{ item.no_appointment_all }}</div>
        </div>
      </div>
    </div> -->
    <div :class="isHide ? '' : 'widDiv'" style="overflow:scroll">
      <table class="table_header" border="1" align="center" cellspacing="0" cellpadding="10">
        <tr style="color:#000;font-size:14px;">
          <th>所属公司</th>
          <th>网点信息</th>
          <th><div class="wrapTitle" style="width:60px">当日预约派工单量</div></th>
          <th><div class="wrapTitle" style="width:60px">当日预约派工单台数</div></th>
          <th><div class="wrapTitle" style="width:60px">当日完工单数</div></th>
          <th><div class="wrapTitle" style="width:60px">当日完工台数</div></th>
          <th><div class="wrapTitle" style="width:60px">当日未完工单量</div></th>
          <th><div class="wrapTitle" style="width:60px">当日未完工单台数</div></th>
          <th><div class="wrapTitle" style="width:60px">累计未完工单量</div></th>
          <th><div class="wrapTitle" style="width:60px">累计未完工单台数</div></th>
          <th><div class="wrapTitle" style="width:60px">未预约工单</div></th>
        </tr>
        <tr v-for="(item, i) in dataList" :key="i">
          <td :rowspan="item.rowspan" v-if="item.rowspan">{{ item.company }}</td>
          <td>
            <div class="wrapTitle">{{ item.websit_name }}{{ item.websit_number }}</div>
          </td>
          <td>{{ item.appoint_reserve_today }}</td>
          <td>{{ item.appoint_reserve_machine_today }}</td>
          <td>{{ item.completed_today }}</td>
          <td>{{ item.completed_machine_today }}</td>
          <td>{{ item.unfinished_today }}</td>
          <td>{{ item.unfinished_machine_today }}</td>
          <td>{{ item.unfinished_all }}</td>
          <td>{{ item.unfinished_machine_all }}</td>
          <td>{{ item.no_appointment_all }}</td>
        </tr>
      </table>
    </div>
    <!-- 搜索提示 -->
    <van-empty v-show="dataList.length === 0" image="search" description="暂无数据" />
  </div>
</template>

<script>
import { Toast, Notify } from 'vant'
import { getUrlKey, debounce, getBlob } from '@/utils/common'
export default {
  data() {
    return {
      // finished: false,

      // pageSize: 15,
      // pageNum: 1,

      keyword: '',
      show: false,
      isHide: true,
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      role_type: 'websit',
      role_typeText: '网点',
      dataList: []
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    resetFn() {
      this.dataList = []
      // this.pageNum = 1
      // this.finished = false
      this.onLoad()
    },
    // debounceChange: debounce(function(val, val2) {
    //   // console.log(val)
    //   if (val < 300 && val2 >= 0 && this.finished === false) {
    //     this.onLoad()
    //   }
    // }, 500),
    // scrollFn(value) {
    //   const aaa = value.target.scrollHeight - value.target.scrollTop - value.target.clientHeight
    //   const bbb = value.target.scrollLeft
    //   this.debounceChange(aaa, bbb)
    // },
    onLoad() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let params = {
        page: 1,
        page_size: -1,
        openid: getUrlKey('openid', window.location.href),
        role_type: 'websit',
        keyword_type: 'websit',
        keyword: this.keyword,
        order_type: 'install',
        export: '0',
        token: JSON.parse(sessionStorage.getItem('mobileOfficeMiniTOKEN'))
      }
      this.postRequestPHP('/services/statistics/order_statistics/lists', params)
        .then(res => {
          let data = res.data.data

          for (const key in data) {
            let arr = Object.values(data[key])
            arr[0].rowspan = arr.length
            for (const val of arr) {
              this.dataList.push({
                company: key,
                ...val
              })
            }
          }
          Toast.clear()
        })
        .catch(err => {
          Notify({ type: 'danger', message: '参数错误' })
          Toast.clear()
        })
    },
    searchFn() {
      this.resetFn()
    },
    keydownFn() {
      this.resetFn()
    },
    downLoadFn() {
      let params = {
        openid: getUrlKey('openid', window.location.href),
        role_type: 'websit',
        keyword_type: 'websit',
        keyword: this.keyword,
        order_type: 'install',
        export: '1',
        page: 1,
        page_size: -1,
        token: JSON.parse(sessionStorage.getItem('mobileOfficeMiniTOKEN'))
      }
      this.postRequestPHP('/services/statistics/order_statistics/lists', params).then(res => {
        uni.navigateTo({
          url: '/pages/master/mine/download?url=' + res.data.filename.file + '&&name=' + res.data.filename.name
        })
      })
    },

    showFn() {
      this.isHide = false
      // this.resetFn()
    },
    // selectFn(value) {
    //   this.show = true
    // },

    goBack() {
      if (this.isHide) {
        this.$router.back()
      } else {
        this.isHide = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapTitle {
  white-space: normal;
  width: 150px;
}

.iconClass {
  font-weight: 700;
  font-size: 24px;
  color: #bfbfbf;
}
.selectOrigin {
  display: inline-block;
  transform: rotate(90deg);
}
.svgClass {
  //   font-size: 20px;
  height: 24px;
  width: 24px;
}

.table_header {
  overflow: scroll;
  width: 100%;
  //   height: 100vw;
  // border-top: 4px solid #efefef;
  white-space: nowrap;
  background-color: rgb(255, 255, 255);
  border-color: #fff;

  .header {
    overflow: hidden;
    border-right: 4px solid #efefef;
    display: inline-block;
    width: 120px;
    height: 50px;
    font-size: 14px;
    // background-color: #ce1919;
    // padding: 0;
  }
  .wrap {
    white-space: pre-wrap;
    width: 50px;
  }
}

.listTitle {
  font-weight: 700;
  font-size: 12px;
}

.widDiv {
  width: calc(100vh - 46px);
  height: calc(100vh - 300px);
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
}
.selectStyle {
  height: 30px;
  border-radius: 5px;
  border: 1px solid #dadada;
}
.inputClass {
  height: 30px;
  border: 1px solid #dddddd;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
